import TargetContent from "../enum/TargetContent";
import { createContext, useEffect, useState } from "react";
import getSlugByTargetContent from "../utils/getSlugByTargetContent";
import { useContext } from "react";
import history from "history/browser";
import WorkListType from "../enum/WorkListType";
import { WorkListContext } from "./WorkListTypeContext";

type MenuContextType = {
    currentActiveContent: TargetContent,
    resetContent: () => void,
    setActiveContent: (targetContent: TargetContent) => void
}

export const MenuContext = createContext<MenuContextType>({} as MenuContextType);

export const MenuProvider = ({ children }) => {

    const [currentActiveContent, setCurrentActiveContent] = useState<TargetContent>(TargetContent.DEFAULT);
    const { activeType, setActiveType } = useContext(WorkListContext)

    const resetContent = () => {
        setCurrentActiveContent(TargetContent.DEFAULT);
        toggleBackHomeVisibility(TargetContent.DEFAULT)
        toggleProjectViewListVisibility(TargetContent.DEFAULT);
        toggleBenefitView2050Visibility(TargetContent.DEFAULT);

    }

    const setActiveContent = (newItem: TargetContent) => {
        toggleBackHomeVisibility(newItem)

        if (newItem !== TargetContent.WORK || (newItem === TargetContent.WORK && document.querySelectorAll(".page.single-project.active").length < 1)) {
            setCurrentActiveContent(newItem);
        }
        console.log('newItem', newItem);
        toggleProjectViewListVisibility(newItem);
        toggleBenefitView2050Visibility(newItem);
    }


    const toggleBackHomeVisibility = (TargetContent) => {
        const backToHome = document.getElementById('back-to-home')

        let isHome = TargetContent === -1
        if (!isHome) {
            backToHome.classList.add('visible')
        } else {
            backToHome.classList.remove('visible')
            removeActivePageClasses()
            updateCurrentContent()
        }
    }

    const toggleProjectViewListVisibility = (TargetContent) => {
        const singleProjectViewList = document.getElementById('single-project-view-list')
        if (!singleProjectViewList) return false

        const workPageID = 1
        const isWorkPage = TargetContent == workPageID
        const isSingleProject = document.querySelectorAll('.page.single-project.active').length > 0

        if (isWorkPage && !isSingleProject) {
            singleProjectViewList.classList.add('visible')

            // reset first active secition
            document.querySelector('.single-project-view__voice[data-open="works-images"]').classList.add('active')
            document.getElementById('works-images').classList.remove('hidden')

            document.querySelector('.single-project-view__voice[data-open="works-lists"]').classList.remove('active')
            document.getElementById('works-lists').classList.add('hidden')

            setActiveType(WorkListType.IMAGE)

        } else {
            singleProjectViewList.classList.remove('visible')
        }

    }
    const toggleBenefitView2050Visibility = (TargetContent) => {
        const BenefitView2050 = document.getElementById('benefit-view-2050')
        if (!BenefitView2050) return false

        const benefitPageID = 2
        const isBenefitPage = TargetContent == benefitPageID

        if (isBenefitPage) {
            BenefitView2050.classList.add('visible')

            // reset first active secition
            document.querySelector('.benefit-view-2050__voice[data-open="benefit-main"]').classList.add('active')
            document.getElementById('benefit-main').classList.remove('hidden')

            document.querySelector('.benefit-view-2050__voice[data-open="benefit-2050"]').classList.remove('active')
            document.getElementById('benefit-2050').classList.add('hidden')

        } else {
            BenefitView2050.classList.remove('visible')
        }
    }

    const removeActivePageClasses = () => {
        const currentActivePage = document.getElementsByClassName("page active");
        [...currentActivePage].forEach(page => {
            if (page) {
                page.classList.add("exit");
                setTimeout(() => {
                    page.classList.remove("active");
                    page.classList.remove("exit");
                    window.scrollTo(0, 0);
                }, 600);
            }
        });


        // remove hovered class
        document.querySelectorAll('.menu-item').forEach(menuItem => {
            menuItem.classList.remove('hovered')
        });

    }

    const updateCurrentContent = () => {
        console.warn("SLUG CHANGE");
        console.log(currentActiveContent);


        const slug = getSlugByTargetContent(currentActiveContent);
        if (!document.getElementById(slug)) return

        document.querySelector('.App').classList.add("full-height-app");
        history.replace(`/${slug}`);
        document.getElementById(slug).classList.add("active");
    }

    useEffect(() => {
        if (currentActiveContent && currentActiveContent !== TargetContent.DEFAULT) {
            console.warn("SLUG CHANGE");
            console.log(currentActiveContent);

            removeActivePageClasses()
            updateCurrentContent()

        }
    }, [currentActiveContent]);

    return (
        <MenuContext.Provider value={{ currentActiveContent, resetContent, setActiveContent }}>
            {children}
        </MenuContext.Provider>
    )
}
