import React from 'react';
import './fonts/Acidic.woff';
import './fonts/Acidic.woff2';
import './App.scss';
import { useRoutes } from "react-router";
import routes from "./router/router";

function CMQ() {

  const content = useRoutes(routes);

  return (
    <div className={"App"} id={"App"}>
      {content}
    </div>
  );
}

export default CMQ;
